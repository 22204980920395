<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.equipos.single') }} {{ $t('app.general.to') }} {{ $t('app.tournament.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">{{ $t('app.torneos.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.torneos.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.torneo_id"
                        :placeholder="$t('app.torneos.single')"
                        :items="torneos"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EquiposAddToTournament",
  data () {
    return {
      equipo: false,
      torneos: [],
      dialog: false,
      item: {
        equipo_id: false,
        torneo_id: false
      }
    }
  },
  mounted() {
    EventBus.$on('equipos-add-to-tournament', async item => {
      this.equipo = item
      this.item.equipo_id = item.id

      this.toggleLoader()
      this.torneos = await this.getTorneos()
      this.torneos = this.torneos.filter(item => item.sucursal_id == item.sucursal_id)
      this.toggleLoader()

      this.dialog = true
    })
  },
  methods: {
    async saveItem () {
      this.$refs.observer.validate()
        .then(async success => {
          if (!success) return

          this.toggleLoader()

          await this.$http.post(route('v1/team/add-to-tournament'), this.item)
            .then(response => {
              if (response.body.code === 200) {
                this.processSuccess(response)

                this.item = {
                  equipo_id: null,
                  torneo_id: null
                }

                requestAnimationFrame(() => {
                  this.$refs.observer.reset()
                })

                this.dialog = false
                EventBus.$emit('reload-items')
              } else {
                this.processError(response)
              }
            }, error => this.processError(error))

          this.toggleLoader()
        })
    }
  }
}
</script>

<style scoped>

</style>
